<template>
  <div>
    <div class="ss-form-rtitle" :class="[highlightErrorField && _field.errorMessage ? `req` : ``]">
      <div class="icn-txt-blk">
        <span
          v-if="_field.errorMessage"
          class="ss-alert alert-r intip4 intip5 intip4bx"
          :data-tool-tip="_field.errorMessage"
        >
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="inline-fa fa-w-18" />
        </span>
        {{ label }}:
      </div>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div class="ss-form-result" :class="[restrictHeight ? 'ss-result-xl' : '']">
      <span :class="[hasChangedValue ? `old` : ``]" v-html="formattedValue(_field.originalValue)" />
      <span v-if="hasChangedValue" class="new" v-html="formattedValue(_field.changedValue)" />
    </div>
    <!-- eslint-enable -->
  </div>
</template>
<script>
import { formatDateInEST } from '~/util/apiDateFormat';
import { formatNumberAsFullNumber } from '~/util/numeral';

export default {
  name: 'ReviewCampaignDisplayField',

  components: {},

  props: {
    field: {
      type: Object,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: false,
      // visits, currency, date
      default: '',
    },
    formatter: {
      type: Function,
      required: false,
      default: undefined,
    },
    highlightErrorField: {
      type: Boolean,
      required: false,
      default: true,
    },
    restrictHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    _field() {
      return this.field ? this.field : {};
    },
    hasChangedValue() {
      if (['visits', 'currency'].includes(this.dataType.toLowerCase())) {
        return this._field.changedValue !== undefined;
      }
      return this._field.changedValue;
    },
  },
  methods: {
    formattedValue(value) {
      const dataType = this.dataType.toLowerCase();
      if (['visits', 'currency'].includes(dataType)) {
        return this.formatNumber(value, dataType);
      }
      if (dataType === 'date') {
        return formatDateInEST(value);
      }
      return value !== undefined && this.formatter ? this.formatter(value) : value;
    },
    formatNumber(n, type) {
      return n !== undefined ? formatNumberAsFullNumber(n, type) : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.flp-top-panels {
  display: flex;
  justify-content: space-between;
}
.ss-form-rtitle {
  display: inline-block;
  margin-right: 10px;
  width: 150px;
  font-size: 15px;
  vertical-align: top;
  position: relative;
}
.ss-form-rtitle.req {
  color: #de284a;
}
.ss-form-result {
  display: inline-block;
  color: #777;
  font-size: 15px;
  max-width: calc(100% - 186px);
  vertical-align: top;
}
.ss-result-xl {
  overflow: scroll;
  max-height: 110px;
}
.mvc-half .ss-form-result {
  max-width: calc(100% - 186px);
}
.new {
  background-color: #ffe578;
  padding: 0px 3px;
  margin-left: 4px;
}
.old {
  text-decoration: line-through;
  opacity: 0.7;
}
.alert-r {
  color: #de284a;
}
.ss-alert {
  position: absolute;
  margin: 1px 0px 0px -18px;
  font-size: 13px;
}
.intip4 {
  position: relative;
  top: 0px;
}
.intip4[data-tool-tip]::after {
  content: attr(data-tool-tip);
  display: block;
  position: absolute;
  width: 220px;
  font-size: 12px;
  line-height: 1.2em;
  background-color: #fdea85;
  color: #5b5b5b;
  padding: 10px 12px;
  border-radius: 3px;
  left: 0;
  margin-left: -113px;
  bottom: 100%;
  transform: scale(0);
  transition: ease 0.3s;
  transform-origin: bottom center;
  z-index: 99;
  pointer-events: none;
}

.intip4[data-tool-tip]::after {
  background-color: #464646;
  color: #fff;
  width: 160px;
  margin-left: -80px;
  font-size: 13px;
  pointer-events: none;
}
.intip5[data-tool-tip]::after {
  text-transform: none;
  width: 245px;
  margin-left: -120px;
  text-align: left;
}
.intip4[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 250%;
}

::v-deep .rsedit {
  .icn-txt-blk {
    // padding-top: 12px;
    // padding-left: 32px;
    .intip4 {
      display: inline-flex;

      svg {
        display: block;
        position: relative;
        right: 1px;
      }
    }
    .intip5[data-tool-tip] {
      &::after {
        margin-left: -35px;
      }
    }
  }
}
</style>
